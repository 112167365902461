import React, { useState, useEffect, useRef } from 'react';
import './App.css';



const App: React.FC = () => {
  // state
 const [isCopied, setIsCopied] = useState(false);

 const [solBalance, setSolBalance] = useState<number | null>(null);
 const [totalReceivedSol, setTotalReceivedSol] = useState<number | null>(null);
 
 // refs for scroll
 const homeRef = useRef<HTMLDivElement>(null);
 const aboutRef = useRef<HTMLDivElement>(null);
 const tokenomicsRef = useRef<HTMLDivElement>(null);
 const roadmapRef = useRef<HTMLDivElement>(null);
 
 const walletAddress = 'CATsewgamj86hDXN2PFArKUx5ok1K9Hz3ntscsBs4hV';
 const MAX_SOL = 1500;

interface FAQItem {
  question: string;
  answer: string;
}

const faqList: FAQItem[] = [
  {
    question: "What chain is $SECAT on?",
    answer: "$SECAT is on the Solana chain."
  },
  {
    question: "What are the complaints about $SECAT?",
    answer: "AmLtgYYyYLKq2Ma9ExsUNDVHKgwrwXck7PzgPaPQ3oqS"
  },
  {
    question: "When will the pre-sale start?",
    answer: "It starts at 2024-11-22 00:00 UTC."
  },
  {
    question: "Is there a discount for presale?",
    answer: "It's important to participate in the pre-sale early.\n\n30% bonus for 0-500 Solana.\n\n15% bonus for 500-1000 Solana.\n\n5% Bonus on 1000~1500 Solanas"
  },
  {
    question: "How long does the presale last?",
    answer: "The 5-day countdown begins when 500 Solana or more is collected. The presale ends 5 days after the countdown or when 1500 Solana is reached."
  },
  {
    question: "Are there any countries where investment is restricted?",
    answer: "You may not participate in the presale if you are not a citizen of Afghanistan, Benin, Bhutan, Bahrain, Egypt, Kuwait, China, Crimea, Cuba, Iran, Iraq, Saudi Arabia, Syria, Oman, UAE, United States, Vatican City or any other country or jurisdiction where such distribution or use would be against local law or regulation."
  },
  {
    question: "Which exchange will $SECAT be listed on right after the presale ends?",
    answer: "After the presale ends, it will be listed on DEXs like Raydium first.\n\nWe aim to be listed on a major exchange in the future."
  },
  {
    question: "When will the $SECAT I purchased be distributed?",
    answer: "The presale ends and the distribution will be as soon as possible. It may take some time depending on the number of participants."
  },
  {
    question: "Is there an airdrop other than the presale?",
    answer: "Some tokens will be airdropped in the future."
  }
 ];

 // copy
const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  } catch (err) {
    console.error('Failed to copy text: ', err);
  }
};

// FAQ state
const [openFAQ, setOpenFAQ] = useState<number | null>(null);

 const [countdown, setCountdown] = useState<{
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}>({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0
});

  // presale start time
  const PRESALE_START_DATE = new Date('2024-11-22T00:00:00Z'); 

  const isPresaleTime = () => {
    const now = new Date();
    return now >= PRESALE_START_DATE;
  };

 const calculatePercentage = (balance: number, maxAmount: number): number => {
   return (balance / maxAmount) * 100;
 };

 const getGaugeWidth = (balance: number | null, maxAmount: number): string => {
  if (balance === null) return "0%";
  if (balance >= maxAmount) return "100%";
  const percentage = calculatePercentage(balance, maxAmount);
  return `${Math.min(percentage, 100)}%`;
};

const formatPercentage = (balance: number | null, maxAmount: number): string => {
  if (balance === null) return "0%";
  if (balance >= maxAmount) return "100.00%";
  const percentage = calculatePercentage(balance, maxAmount);
  
  if (percentage >= 99.99) return "99.99%";
  if (percentage >= 99.9) return percentage.toFixed(2) + "%";
  return percentage.toFixed(1) + "%";
};


 const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
   ref.current?.scrollIntoView({ behavior: 'smooth' });
 };

 useEffect(() => {
  const fetchBalanceData = async () => {
    console.log('Fetching balance data...');  // fun start
    console.log('Wallet Address:', walletAddress);  // balanace
    
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/balance/${walletAddress}`);
      console.log('Response status:', response.status);  // respose
      
      if (!response.ok) {
        console.error('Failed to fetch balance data:', response.statusText);
        return;
      }
      
      const data = await response.json();
      console.log('Fetched data:', data);  // recive data
      setSolBalance(data.balance);
      console.log('Balance set to:', data.balance);  // balance
    } catch (error) {
      console.error('Error details:', error);  // err
    }
  };

  console.log('useEffect triggered');  // useEffect
  fetchBalanceData();
}, [walletAddress]);

  // countdown useEffect
  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = PRESALE_START_DATE.getTime() - now;

      if (difference <= 0) {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        };
      }

      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    };

    const timer = setInterval(() => {
      setCountdown(calculateTimeLeft());
    }, 1000);

    // Initial calculation
    setCountdown(calculateTimeLeft());

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const adjustFontSize = () => {
      console.log('Adjusting font size...'); // 함수 실행 확인
      const gaugeContainer = document.querySelector('.gauge-container') as HTMLElement;
      const walletAddress = document.querySelector('.wallet-address') as HTMLElement;
      const address = walletAddress?.textContent || '';
      
      if (gaugeContainer && walletAddress) {
        const gaugeWidth = gaugeContainer.offsetWidth;
        console.log('Current gauge width:', gaugeWidth); // 현재 너비 확인
        
        // 기본 최대/최소 폰트 크기 설정
        const maxFontSize = 24;
        const minFontSize = 11;
        
        let calculatedSize = (gaugeWidth * 0.02);
        let fontSize = Math.min(Math.max(calculatedSize, minFontSize), maxFontSize);
        
        if (address.length > 40) {
          fontSize = fontSize * (40 / address.length);
        }
        
        fontSize = Math.round(fontSize);
        console.log('New font size:', fontSize); // 새로운 폰트 크기 확인
        
        walletAddress.style.fontSize = `${fontSize}px`;
      }
    };
  
    // 초기 실행
    console.log('Initial setup'); // 초기 설정 확인
    adjustFontSize();
  
    // resize 이벤트 리스너
    const handleResize = () => {
      console.log('Window resized!'); // resize 이벤트 발생 확인
      adjustFontSize();
    };
  
    window.addEventListener('resize', handleResize);
    
    // cleanup
    return () => {
      console.log('Cleanup'); // cleanup 확인
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

 return (  
  
   <div className="App">
     <nav className="navbar">
       <div className="nav-content">
         <div className="nav-menu">
           <img 
             src="/logo499.png"
             alt="Logo" 
             className="nav-logo" 
           />
           <span className="nav-brand">Strawberryeatingcat</span>
           <button onClick={() => scrollToSection(aboutRef)}>About</button>
           <button onClick={() => scrollToSection(tokenomicsRef)}>Tokenomics</button>
           <button onClick={() => scrollToSection(roadmapRef)}>Roadmap</button>
         </div>
       </div>
     </nav>

     <section ref={homeRef} className="section home-section">
  <img 
    src="/1.png"
    alt="Welcome"
    className="welcome-image"
  />
<div className="presale-container">
<div className={`balance-box ${!isPresaleTime() ? 'locked' : ''}`}>
   {isPresaleTime() ? (
     // Presale Started Content
     <div className="presale-content">
       <h3>Presale Status</h3>
       {solBalance !== null ? (
        <div className="balance-content">
  <div className="gauge-container">
    <div
      className="gauge-fill"
      style={{ width: getGaugeWidth(solBalance, MAX_SOL) }}
    >
      <span className="gauge-text">
        {formatPercentage(solBalance, MAX_SOL)}
      </span>
    </div>
  </div>
  <p className="balance-text">
    {solBalance.toFixed(4)} / {MAX_SOL.toLocaleString()} SOL
  </p>
  <div className="wallet-info">
  <div className="wallet-address-container">
  <div className="wallet-address">
    
      {walletAddress}
      </div>
    </div>
    <div className="button-container">
    {isCopied && <div className="copy-message">Copied!</div>}
    <button 
      className="icon-button"
      onClick={() => copyToClipboard(walletAddress)}
      title="Copy address"
    >
      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </button>
    <a 
      href={`https://explorer.solana.com/address/${walletAddress}/tokens`}
      target="_blank"
      rel="noopener noreferrer"
      className="icon-button"
      title="View on Solscan"
    >
      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 13V19C18 19.5304 17.7893 20.0391 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 3H21V9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 14L21 3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </a>
  </div>
  </div>
</div>

       ) : (
         <p>Loading Presale Status...</p>
       )}
     </div>
   ) : (
     // Countdown Content
     <div className="presale-content">
       <h3>Presale Status</h3>
       <div className="presale-info">
         <div className="start-date">
           <div className="info-label">Start Date</div>
           <div className="date-value">November 22, 2024 00:00 UTC</div>
         </div>
         <div className="countdown-container">
           <div className="info-label">Time Remaining</div>
           <div className="countdown-grid">
             <div className="time-block">
               <span className="time-value">{countdown.days}</span>
               <span className="time-label">Days</span>
             </div>
             <div className="time-block">
               <span className="time-value">{String(countdown.hours).padStart(2, '0')}</span>
               <span className="time-label">Hours</span>
             </div>
             <div className="time-block">
               <span className="time-value">{String(countdown.minutes).padStart(2, '0')}</span>
               <span className="time-label">Min</span>
             </div>
             <div className="time-block">
               <span className="time-value">{String(countdown.seconds).padStart(2, '0')}</span>
               <span className="time-label">Sec</span>
             </div>
           </div>
         </div>
       </div>
     </div>
   )}
 </div>

 {isPresaleTime() && (
   <div className="notice-box">
     <h4>⚠️ Important Notice</h4>
     <ul>
       <li>Minimum contribution: 0.1 SOL</li>
       <li>Send SOL only from wallets you own</li>
       <li>NEVER send funds directly from exchanges!</li>
       <li>There is a possibility of high price volatility and potential loss of investment</li>
     </ul>
   </div>
 )}
</div>
</section>
<div className="section-divider">
  <div className="crayon-line"></div>
</div>
<section ref={aboutRef} className="section about-section">
  <h2>About</h2>
  <div className="about-container">
    <div className="about-content">
      <div className="about-flex">
        <div className="about-text-container">
          <p className="about-text">
            Welcome to the sweetest corner of the meme coin universe! 🍓🐱
          </p>
          <p className="about-text">
            StrawberryEatingCat was born from a simple desire: to add a sprinkle of cuteness 
            and a dash of sweetness to the crypto world. Just like a cat delicately enjoying 
            a fresh strawberry, we believe meme coins can be both adorable and delightful.
          </p>
        </div>
        <div className="about-logo">
          <img src="/logo100.png" alt="Logo" />
        </div>
      </div>
      <p className="about-text highlight">
        Our mission is simple: spreading joy and sweetness across the blockchain, 
        one cute token at a time!
      </p>
    </div>
  </div>
</section>
     <div className="section-divider">
  <div className="crayon-line"></div>
</div>
<section ref={tokenomicsRef} className="section tokenomics-section">
  <h2>Tokenomics</h2>
  <div className="tokenomics-container">
    <div className="tokenomics-item">
      <h3>Total Supply</h3>
      <div className="token-supply">
        <span className="supply-number">2,147,483,647</span>
        <span className="supply-note">Maximum value of a 32-bit integer (int max)</span>
      </div>
    </div>
    
    <div className="tokenomics-item">
      <h3>Token Distribution</h3>
      <div className="distribution-info">
        <p className="presale-info">20% allocated for presale</p>
        <p className="funds-info">
          The majority of funds raised will be used for:
          <ul>
            <li>Liquidity provision</li>
            <li>Market making activities</li>
          </ul>
        </p>
      </div>
    </div>
  </div>
</section>
     <div className="section-divider">
  <div className="crayon-line"></div>
</div>
<section ref={roadmapRef} className="section roadmap-section">
  <h2>Roadmap</h2>
  <div className="roadmap-container">
    <div className="hodl-message">
      <div className="hodl-text-wrapper">
        <span className="hodl-text">BUY</span>
        <span className="hodl-and">AND</span>
        <span className="hodl-text">HODL!</span>
      </div>
      <div className="sparkles"></div>
    </div>
  </div>
</section>

<div className="section-divider">
  <div className="crayon-line"></div>
</div>
<section className="section faq-section">
  <h2>FAQ</h2>
  <div className="faq-container">
    {faqList.map((faq, index) => (
      <div 
        key={index} 
        className={`faq-item ${openFAQ === index ? 'open' : ''}`}
      >
        <button
          className="faq-question"
          onClick={() => setOpenFAQ(openFAQ === index ? null : index)}
        >
          {faq.question}
          <span className="faq-icon">
            {openFAQ === index ? '−' : '+'}
          </span>
        </button>
        <div className="faq-answer">
          <p>{faq.answer}</p>
        </div>
      </div>
    ))}
  </div>
</section>

     <div className="section-divider">
  <div className="crayon-line"></div>
</div>
<footer className="footer">
  <div className="footer-content">
  <div className="footer-top">
  <div className="social-links">
    <a href="https://x.com/SECAT_meme" target="_blank" rel="noopener noreferrer">
      <img src="/xlogo1.png" alt="Twitter" className="social-icon" />
    </a>
    <a href="https://x.com/SECAT_meme" target="_blank" rel="noopener noreferrer">
      <img src="/xlogo2.png" alt="X" className="social-icon" />
    </a>
  </div>
  <img src="/logo100.png" alt="Logo" className="footer-logo-img" />
</div>
    <div className="footer-bottom">
      <p className="risk-warning">
      Risk Warning: $SECAT is a meme coin with no intrinsic value. Do not invest money you cannot afford to lose.
      </p>
    </div>
  </div>
</footer>
   </div>
 );
};

export default App;